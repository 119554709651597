





















































































import { Vue, Component, Prop, Watch, Model } from "vue-property-decorator";

@Component({ name: "Select" })
export default class Select extends Vue {
  @Model("input", { default: "" }) public readonly value!: string;
  @Prop({ type: String }) public readonly label!: string;
  @Prop({ type: Boolean, default: false }) public readonly disabled!: boolean;
  @Prop({ type: String }) public readonly id!: string;
  @Prop({ type: String }) public readonly name!: string;
  @Prop({ type: String, default: "text" }) public readonly type!: string;
  @Prop({ type: Boolean }) public readonly touched!: boolean;
  @Prop({ type: Boolean }) public readonly shouldValidate!: boolean;
  @Prop({ type: Boolean }) public readonly valid!: boolean;
  @Prop({ type: String }) public readonly placeholder!: string;
  @Prop({ type: String, default: "normal" })
  public readonly stype!: "normal" | "min";
  @Prop({ type: [String, Number] })
  public readonly maxHeightMenu!: string | number;
  @Prop({ type: Array }) public readonly options!: Array<{
    label: string;
    value: string;
  }>;

  private isOpen: boolean = false;
  private isFocus: boolean = false;
  private innerValue: string = this.value;
  private optionsPosition: "top" | "bottom" = "bottom";

  private get innerLabel() {
    if (this.value) {
      const idx = this.options.findIndex(option => option.value === this.value);

      return (this.options[idx] || { label: "" }).label;
    }

    return "";
  }

  private get inputID(): string {
    return (
      this.id || `${this.name}-${(Number(Math.random().toFixed(3)) * 100) ^ 0}`
    );
  }

  public isValidation = ({
    valid,
    touched,
    shouldValidate
  }: {
    valid: boolean;
    touched: boolean;
    shouldValidate: boolean;
  }) => {
    return !valid && touched && shouldValidate;
  };

  private focusHandler(evt: Event): void {
    if (evt.type === "focus" && !this.disabled) {
      this.isFocus = true;
    } else {
      this.isFocus = false;
      this.isOpen = false;

      this.$emit("blur", {
        type: "blur",
        target: { name: this.name, value: this.innerValue }
      });
    }
  }

  private setValue(option: { value: string; label: string }): void {
    this.innerValue = option.value;
    this.isOpen = false;

    this.$emit("input", this.innerValue);
    this.$emit("change", {
      type: "change",
      target: { name: this.name, value: this.innerValue }
    });
  }

  private toggleHandler(): void {
    if (!this.disabled) {
      this.isOpen = !this.isOpen;
    }
  }

  @Watch("isOpen")
  private calcPosition() {
    if (this.$refs.select && this.isOpen) {
      const elem = this.$refs.select as HTMLElement;

      const { top, height } = elem.getBoundingClientRect();

      if (window.innerHeight - top + height < (this.maxHeightMenu || 250)) {
        this.optionsPosition = "top";
      } else {
        this.optionsPosition = "bottom";
      }
    }
  }
}
