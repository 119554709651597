var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"select",class:[
    'form-item',
    ("form-item-" + _vm.stype),
    {
      valid: _vm.valid,
      invalid: _vm.isValidation(_vm.$props)
    }
  ]},[(_vm.label !== undefined)?_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{class:[
      'form-item-select',
      {
        'select-is-focus': _vm.isFocus,
        'select-is-open': _vm.isOpen,
        'select-is-disabled': _vm.disabled
      }
    ],attrs:{"tabindex":"-1"},on:{"focus":_vm.focusHandler,"blur":function (evt) {
        _vm.focusHandler(evt);
        _vm.$emit('blur', { target: { name: _vm.name, value: _vm.innerValue } });
      }}},[_c('div',{staticClass:"form-item-select__control",on:{"click":_vm.toggleHandler}},[_c('div',{staticClass:"form-item-select__container"},[(_vm.innerLabel)?_c('div',{staticClass:"form-item-select__value"},[_vm._v(" "+_vm._s(_vm.innerLabel)+" ")]):_vm._e(),(_vm.placeholder !== undefined && !_vm.innerLabel)?_c('div',{staticClass:"form-item-select__placeholder"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e(),_c('div',{staticClass:"form-item-select__input"},[_c('input',{attrs:{"id":_vm.id,"autocomplete":"off","tabindex":"0","aria-autocomplete":"list","auto-correct":"off","name":_vm.name},domProps:{"value":_vm.innerValue}})])]),_vm._m(0)]),_c('transition',{attrs:{"name":("form-item-select__menu_" + _vm.optionsPosition)}},[(_vm.isOpen && _vm.options !== null)?_c('div',{class:[
          'form-item-select__menu',
          ("form-item-select__menu_" + _vm.optionsPosition)
        ],style:({
          'max-height': _vm.maxHeightMenu
        })},_vm._l((_vm.options),function(option){return _c('div',{key:option.value,staticClass:"form-item-select__menu-option",on:{"click":function($event){return _vm.setValue(option)}}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-item-select__indicators"},[_c('span')])}]

export { render, staticRenderFns }